export const API_DOMAIN = process ? process.env.REACT_APP_API_DOMAIN : null;

export const LOCALE_SHORTHANDS = {
  english: 'en-US',
};

export const COLORS = {
  red: '#EF4444', // uof
  yellow: '#F59E0B', // iwu
  orange: '#FF6600', // csu
  green: '#10B981', // nr
  blue: '#3B82F6', // tu
  grey: '#6B7280', // ps,
  periwinkle: '#6366f1', // k9d
};

export const STATISTIC_CONFIGS = {
  // POLICE DATA COLUMNS
  uof: {
    displayName: 'Reported Use of Force',
    color: COLORS.red,
    format: 'long',
    showDelta: true,
    hasPrimary: true,
    tableConfig: {
      notes:
        'USE OF FORCE\n\nReported Use of Force includes all use of weapons and weaponless physical force (takedowns, control holds, punches and kicks) reported by police. In most cases, this does not include threats or “displays of force” such as pointing weapons at people. This also does not include any use of force that police failed to properly document or disclose upon request.',
    },
  },
  ps: {
    displayName: 'Police Shootings',
    color: COLORS.grey,
    format: 'long',
    showDelta: true,
    hasPrimary: true,
    tableConfig: {
      notes:
        'POLICE SHOOTINGS\n\nThe total number of incidents in which police discharged a firearm at another person, whether or not the bullet struck anyone. This does not include accidental discharges, warning shots or discharges at animals.',
    },
  },
  tu: {
    displayName: 'Taser Uses',
    color: COLORS.blue,
    format: 'long',
    showDelta: true,
    hasPrimary: true,
    tableConfig: {
      notes:
        'TASER/ELECTRONIC WEAPONS\n\nThe total number of incidents in which police deployed a taser via probes or drive stun mode, or used other electronic control weapons, against another person. This does not include “arcing” or displaying the taser as a warning or threat.',
    },
  },
  k9d: {
    displayName: 'K-9',
    color: COLORS.periwinkle,
    format: 'long',
    showDelta: true,
    hasPrimary: true,
    tableConfig: {
      notes:
        'K-9 CONTACTS\n\nThe total number of incidents involving physical contact between a police K-9 and another person, including using the K-9 to bite or physically apprehend a person.',
    },
  },
  nr: {
    displayName: 'Neck Restraints',
    color: COLORS.green,
    format: 'long',
    showDelta: true,
    hasPrimary: true,
    tableConfig: {
      notes:
        'NECK RESTRAINTS\n\nThe total number of incidents where a chokehold, carotid restraint, lateral vascular neck restraint or other neck restraint was used against another person.',
    },
  },
  iwu: {
    displayName: 'Baton/Impact Weapons',
    color: COLORS.yellow,
    format: 'long',
    showDelta: true,
    hasPrimary: true,
    tableConfig: {
      notes:
        'BATON/IMPACT WEAPONS\nThe total number of incidents in which police deployed an impact weapon, baton, blunt object, or discharged an impact projectile against another person. This includes the use of an impact weapon to apply a control hold or other hold on a person, but does not include cases where an impact weapon is displayed only.',
    },
  },
  csu: {
    displayName: 'Chemical Spray',
    color: COLORS.orange,
    format: 'long',
    showDelta: true,
    hasPrimary: true,
    tableConfig: {
      notes:
        'CHEMICAL SPRAY\n\nThe total number of incidents where police deployed OC Spray, pepper spray, pepper balls, or CS gas against another person. This does not include cases where chemical spray is displayed only.',
    },
  },
  population: {
    displayName: 'Population Covered',
    format: 'short',
    color: '#b6854d',
    hideZero: true,
    mapConfig: {
      spike: true,
    },
    tableConfig: {
      notes:
        'POPULATION COVERED\n\nTotal population within the primary jurisdiction of agenc(ies) we have obtained use of force data on."',
    },
  },
  percent_population: {
    displayName: '% Population Covered',
    format: '%',
    color: '#b6854d',
    hideZero: false,
    mapConfig: {
      spike: true,
    },
    tableConfig: {
      notes:
        '% POPULATION COVERED\n\nPopulation compared to the state\'s overall population."',
    },
  },
};

export const PRIMARY_STATISTICS = ['uof', 'ps', 'tu', 'iwu'];

export const LEVEL_STATISTICS = [...PRIMARY_STATISTICS];

export const TABLE_STATISTICS = [...PRIMARY_STATISTICS, 'k9d', 'nr', 'csu'];

export const TABLE_STATISTICS_EXPANDED = Object.keys(STATISTIC_CONFIGS).filter(
  (statistic) => !STATISTIC_CONFIGS[statistic]?.tableConfig?.hide
);

export const MAP_STATISTICS = [...PRIMARY_STATISTICS];

export const TIMESERIES_STATISTICS = [
  ...PRIMARY_STATISTICS,
  'k9d',
  'nr',
  'csu',
];

export const BRUSH_STATISTICS = ['uof'];

export const UPDATES_COUNT = 5;

export const DISTRICT_TABLE_COUNT = 40;

export const D3_TRANSITION_DURATION = 300;

export const MINIGRAPH_LOOKBACK_DAYS = 5000;

export const TESTED_EXPIRING_DAYS = 7;

export const UNASSIGNED_STATE_CODE = 'UN';

export const UNKNOWN_DISTRICT_KEY = 'Unknown';

export const DISTRICT_START_DATE = '2020-04-26';

export const DISTRICT_TEST_END_DATE = '2021-02-02';

export const ISO_DATE_REGEX = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/g;

export const ISO_SUFFIX = 'T00:00:00-07:00';

export const SPRING_CONFIG_NUMBERS = {clamp: true, precision: 1};

export const TIMESERIES_CHART_TYPES = {
  total: 'Cumulative',
  delta: 'Daily',
};

export const TIMESERIES_LOOKBACK_DAYS = [null, 90, 30];

export const MAP_VIZS = {
  CHOROPLETH: 0,
  BUBBLE: 1,
  SPIKE: 2,
};

export const MAP_VIEWS = {
  STATES: 0,
  DISTRICTS: 1,
};

export const MAP_TYPES = {
  COUNTRY: 0,
  STATE: 1,
};

export const MAPS_DIR =
  process && process.env.NODE_ENV === 'production'
    ? '/mini_maps'
    : '/projected_maps';

export const MAP_META = {
  AL: {
    name: 'Alabama',
    geoDataFile: `${MAPS_DIR}/AL.json`,
    mapType: MAP_TYPES.STATE,
  },
  AK: {
    name: 'Alaska',
    geoDataFile: `${MAPS_DIR}/AK.json`,
    mapType: MAP_TYPES.STATE,
  },
  AZ: {
    name: 'Arizona',
    geoDataFile: `${MAPS_DIR}/AZ.json`,
    mapType: MAP_TYPES.STATE,
  },
  AR: {
    name: 'Arkansas',
    geoDataFile: `${MAPS_DIR}/AR.json`,
    mapType: MAP_TYPES.STATE,
  },
  CA: {
    name: 'California',
    geoDataFile: `${MAPS_DIR}/CA.json`,
    mapType: MAP_TYPES.STATE,
  },
  CO: {
    name: 'Colorado',
    geoDataFile: `${MAPS_DIR}/CO.json`,
    mapType: MAP_TYPES.STATE,
  },
  CT: {
    name: 'Connecticut',
    geoDataFile: `${MAPS_DIR}/CT.json`,
    mapType: MAP_TYPES.STATE,
  },
  DE: {
    name: 'Delaware',
    geoDataFile: `${MAPS_DIR}/DE.json`,
    mapType: MAP_TYPES.STATE,
  },
  DC: {
    name: 'District Of Columbia',
    geoDataFile: `${MAPS_DIR}/DC.json`,
    mapType: MAP_TYPES.STATE,
  },
  // FM: {
  //   name: 'Federated States Of Micronesia',
  //   geoDataFile: `${MAPS_DIR}/FM.json`,
  //   mapType: MAP_TYPES.STATE,
  // },
  FL: {
    name: 'Florida',
    geoDataFile: `${MAPS_DIR}/FL.json`,
    mapType: MAP_TYPES.STATE,
  },
  GA: {
    name: 'Georgia',
    geoDataFile: `${MAPS_DIR}/GA.json`,
    mapType: MAP_TYPES.STATE,
  },
  // GU: {
  //   name: 'Guam',
  //   geoDataFile: `${MAPS_DIR}/GU.json`,
  //   mapType: MAP_TYPES.STATE,
  // },
  HI: {
    name: 'Hawaii',
    geoDataFile: `${MAPS_DIR}/HI.json`,
    mapType: MAP_TYPES.STATE,
  },
  ID: {
    name: 'Idaho',
    geoDataFile: `${MAPS_DIR}/ID.json`,
    mapType: MAP_TYPES.STATE,
  },
  IL: {
    name: 'Illinois',
    geoDataFile: `${MAPS_DIR}/IL.json`,
    mapType: MAP_TYPES.STATE,
  },
  IN: {
    name: 'Indiana',
    geoDataFile: `${MAPS_DIR}/IN.json`,
    mapType: MAP_TYPES.STATE,
  },
  IA: {
    name: 'Iowa',
    geoDataFile: `${MAPS_DIR}/IA.json`,
    mapType: MAP_TYPES.STATE,
  },
  KS: {
    name: 'Kansas',
    geoDataFile: `${MAPS_DIR}/KS.json`,
    mapType: MAP_TYPES.STATE,
  },
  KY: {
    name: 'Kentucky',
    geoDataFile: `${MAPS_DIR}/KY.json`,
    mapType: MAP_TYPES.STATE,
  },
  LA: {
    name: 'Louisiana',
    geoDataFile: `${MAPS_DIR}/LA.json`,
    mapType: MAP_TYPES.STATE,
  },
  ME: {
    name: 'Maine',
    geoDataFile: `${MAPS_DIR}/ME.json`,
    mapType: MAP_TYPES.STATE,
  },
  // MH: {
  //   name: 'Marshall Islands',
  //   geoDataFile: `${MAPS_DIR}/MH.json`,
  //   mapType: MAP_TYPES.STATE,
  // },
  MD: {
    name: 'Maryland',
    geoDataFile: `${MAPS_DIR}/MD.json`,
    mapType: MAP_TYPES.STATE,
  },
  MA: {
    name: 'Massachusetts',
    geoDataFile: `${MAPS_DIR}/MA.json`,
    mapType: MAP_TYPES.STATE,
  },
  MI: {
    name: 'Michigan',
    geoDataFile: `${MAPS_DIR}/MI.json`,
    mapType: MAP_TYPES.STATE,
  },
  MN: {
    name: 'Minnesota',
    geoDataFile: `${MAPS_DIR}/MN.json`,
    mapType: MAP_TYPES.STATE,
  },
  MS: {
    name: 'Mississippi',
    geoDataFile: `${MAPS_DIR}/MS.json`,
    mapType: MAP_TYPES.STATE,
  },
  MO: {
    name: 'Missouri',
    geoDataFile: `${MAPS_DIR}/MO.json`,
    mapType: MAP_TYPES.STATE,
  },
  MT: {
    name: 'Montana',
    geoDataFile: `${MAPS_DIR}/MT.json`,
    mapType: MAP_TYPES.STATE,
  },
  NE: {
    name: 'Nebraska',
    geoDataFile: `${MAPS_DIR}/NE.json`,
    mapType: MAP_TYPES.STATE,
  },
  NV: {
    name: 'Nevada',
    geoDataFile: `${MAPS_DIR}/NV.json`,
    mapType: MAP_TYPES.STATE,
  },
  NH: {
    name: 'New Hampshire',
    geoDataFile: `${MAPS_DIR}/NH.json`,
    mapType: MAP_TYPES.STATE,
  },
  NJ: {
    name: 'New Jersey',
    geoDataFile: `${MAPS_DIR}/NJ.json`,
    mapType: MAP_TYPES.STATE,
  },
  NM: {
    name: 'New Mexico',
    geoDataFile: `${MAPS_DIR}/NM.json`,
    mapType: MAP_TYPES.STATE,
  },
  NY: {
    name: 'New York',
    geoDataFile: `${MAPS_DIR}/NY.json`,
    mapType: MAP_TYPES.STATE,
  },
  NC: {
    name: 'North Carolina',
    geoDataFile: `${MAPS_DIR}/NC.json`,
    mapType: MAP_TYPES.STATE,
  },
  ND: {
    name: 'North Dakota',
    geoDataFile: `${MAPS_DIR}/ND.json`,
    mapType: MAP_TYPES.STATE,
  },
  // MP: {
  //   name: 'Northern Mariana Islands',
  //   geoDataFile: `${MAPS_DIR}/MP.json`,
  //   mapType: MAP_TYPES.STATE,
  // },
  OH: {
    name: 'Ohio',
    geoDataFile: `${MAPS_DIR}/OH.json`,
    mapType: MAP_TYPES.STATE,
  },
  OK: {
    name: 'Oklahoma',
    geoDataFile: `${MAPS_DIR}/OK.json`,
    mapType: MAP_TYPES.STATE,
  },
  OR: {
    name: 'Oregon',
    geoDataFile: `${MAPS_DIR}/OR.json`,
    mapType: MAP_TYPES.STATE,
  },
  PA: {
    name: 'Pennsylvania',
    geoDataFile: `${MAPS_DIR}/PA.json`,
    mapType: MAP_TYPES.STATE,
  },
  RI: {
    name: 'Rhode Island',
    geoDataFile: `${MAPS_DIR}/RI.json`,
    mapType: MAP_TYPES.STATE,
  },
  SC: {
    name: 'South Carolina',
    geoDataFile: `${MAPS_DIR}/SC.json`,
    mapType: MAP_TYPES.STATE,
  },
  SD: {
    name: 'South Dakota',
    geoDataFile: `${MAPS_DIR}/SD.json`,
    mapType: MAP_TYPES.STATE,
  },
  TN: {
    name: 'Tennessee',
    geoDataFile: `${MAPS_DIR}/TN.json`,
    mapType: MAP_TYPES.STATE,
  },
  TX: {
    name: 'Texas',
    geoDataFile: `${MAPS_DIR}/TX.json`,
    mapType: MAP_TYPES.STATE,
  },
  UT: {
    name: 'Utah',
    geoDataFile: `${MAPS_DIR}/UT.json`,
    mapType: MAP_TYPES.STATE,
  },
  VT: {
    name: 'Vermont',
    geoDataFile: `${MAPS_DIR}/VT.json`,
    mapType: MAP_TYPES.STATE,
  },
  // VI: {
  //   name: 'Virgin Islands',
  //   geoDataFile: `${MAPS_DIR}/VI.json`,
  //   mapType: MAP_TYPES.STATE,
  // },
  VA: {
    name: 'Virginia',
    geoDataFile: `${MAPS_DIR}/VA.json`,
    mapType: MAP_TYPES.STATE,
  },
  WA: {
    name: 'Washington',
    geoDataFile: `${MAPS_DIR}/WA.json`,
    mapType: MAP_TYPES.STATE,
  },
  WV: {
    name: 'West Virginia',
    geoDataFile: `${MAPS_DIR}/WV.json`,
    mapType: MAP_TYPES.STATE,
  },
  WI: {
    name: 'Wisconsin',
    geoDataFile: `${MAPS_DIR}/WI.json`,
    mapType: MAP_TYPES.STATE,
  },
  WY: {
    name: 'Wyoming',
    geoDataFile: `${MAPS_DIR}/WY.json`,
    mapType: MAP_TYPES.STATE,
  },
  US: {
    name: 'United States',
    geoDataFile: `${MAPS_DIR}/US.json`,
    mapType: MAP_TYPES.COUNTRY,
  },
};

export const MAP_DIMENSIONS = [432, 488];
export const MAP_LEGEND_HEIGHT = 50;

export const STATE_NAMES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  // FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  // GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  // MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  // VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  US: 'United States',
  [UNASSIGNED_STATE_CODE]: 'Unassigned',
};

export const STATE_POPULATION = {
  AL: 5024279,
  AK: 733391,
  AZ: 7151502,
  AR: 3011524,
  CA: 39538223,
  CO: 5773714,
  CT: 3605944,
  DE: 989948,
  DC: 689545,
  FL: 21538187,
  GA: 10711908,
  HI: 1455271,
  ID: 1839106,
  IL: 12812508,
  IN: 6785528,
  IA: 3190369,
  KS: 2937880,
  KY: 4505836,
  LA: 4657757,
  ME: 1362359,
  MD: 6177224,
  MA: 7029917,
  MI: 10077331,
  MN: 5706494,
  MS: 2961279,
  MO: 6154913,
  MT: 1084225,
  NE: 1961504,
  NV: 3104614,
  NH: 1377529,
  NJ: 9288994,
  NM: 2117522,
  NY: 20201249,
  NC: 10439388,
  ND: 779094,
  OH: 11799448,
  OK: 3959353,
  OR: 4237256,
  PA: 13002700,
  RI: 1097379,
  SC: 5118425,
  SD: 886667,
  TN: 6910840,
  TX: 29145505,
  UT: 3271616,
  VT: 643077,
  VA: 8631393,
  WA: 7705281,
  WV: 1793716,
  WI: 5893718,
  WY: 576851,
  US: 331449281,
};

const stateCodes = [];
const stateCodesMap = {};
Object.keys(STATE_NAMES).map((key, index) => {
  stateCodesMap[STATE_NAMES[key]] = key;
  stateCodes.push({code: key, name: STATE_NAMES[key]});
  return null;
});
export const STATE_CODES = stateCodesMap;
export const STATE_CODES_ARRAY = stateCodes;
